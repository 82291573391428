/* NavBar.css */

.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 74px;
    justify-content: space-between;
    align-items: center;
    background-color: #1f4f8f;
    padding: 0 20px; 
    z-index: 99999999999 !important;
  
}
.navbar .logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 26px;
    margin-left: 4px ;
}
  
  .logo img {
    max-height: 40px; 
  }
  

  /* navbar.css */
.profile-section {
  display: flex;
  align-items: center;
  position: relative;
  float: right;
  margin-right: 40px;
 
}
.profile-section span{
  color: white;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-section .dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white !important;
 
}
.profile-section .dropdown-button :hover{
    background-color: transparent !important;
}

.dropdown-menu {
  position: absolute;
  top: 150%;
  right: 10px;
  background-color: #343a40;
  border: 1px solid#343a40;
  border-radius: 5px;
  z-index: 10;
  color: white;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  width : 100px;
  height: 30px;
}
.logout-button:hover {
  background-color: #16181a;
}

@media screen and (max-width: 550px){
  .logo img {
    max-height: 30px; 
  }
}
@media screen and (max-width: 400px){
  .logo img {
    max-height: 25px; 
  }
}

.hamburger-menu {
   margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    width: 20px;
    height: 18px;
    cursor: pointer;
  }
  
  .line1 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 60%;
  }
  .line2 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 90%;
  }
  .line3 {
    background-color: rgb(181, 176, 176);
    height: 1.5px;
    width: 75%;
  }