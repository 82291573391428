.notification {
    position: fixed;
    top: 85px;
    right: 5px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    z-index: 9999999;
  }
  
  .success {
    background-color: #4caf50;
  }
  
  .error {
    background-color: #f44336;
  }
  