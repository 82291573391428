
.form-container h3{
text-align: center;
 margin-top: 100px;
}
button:hover{
  background-color: #14396a;
}
  .form-content{
    width: 500px;
    margin: auto ;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    /* margin-bottom: 50px; */
    margin-left: 50px;
    margin-right: 50px;
  }
 
  .form-container .form-lable-input {
    display: block;
    margin-bottom: 3px;

    margin-top: 10px;
    text-align: left;
  }
  
  .form-container .form-input[type='text'],
  .form-container .form-input[type='email'] {
    width: calc(100% - 16px);
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .form-container .next-button {
    padding: 8px 16px;
    background-color: #1f4f8f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    margin: auto;
    margin-top: 20px;
  }
  .form-container .next-button:hover{
    background-color: #1a4379;
  }
  
  .code-input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .code-input input {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    text-align: center;
    font-size: 18px;
  }
  .form-heading{
    text-align: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  .form-heading-page-count{
    margin-left: auto;
  }
  .form-details{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .form-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: auto;
    width: 200px;
    margin-top: 20px;
   
  }
  .form-buttons p{
    width: auto;
    font-size: 12px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .form-buttons button{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
  .form-heading-container{
    display: flex ;
    flex-direction: row;
    align-items: flex-start;
/* background-color: aqua; */

  }
  .form-heading-number{
    display: flex;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #1c4e8dcc;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }
  .form-heading-number p{
    margin: auto;
    
  }


  @media screen and (max-width: 600px) {
    .form-content {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  @media screen and (max-width: 500px) {
    .form-content {
      width: 350px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: 400px) {
    .form-content {
      width: 300px;
      padding: 15px;
    }
  }
  
  @media screen and (max-width: 350px) {
    .form-content {
      width: 250px;
      padding: 10px;
    }
  }

  