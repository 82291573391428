.App {
 
 
  margin: 0;
  padding: 0;
}
.component-container{
  display: flex;
  flex-direction: column;
  align-items: center;

}

